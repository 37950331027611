import { Pipe, PipeTransform } from '@angular/core';
import { UploadFile } from '../../core/models/models';
import { environment } from '../../../environments/environment';

@Pipe({
  name: 'pathImage',
  standalone: true,
  pure: true
})
export class PathImagePipe implements PipeTransform {
  transform(
    value: UploadFile | undefined | null | string,
    view: 'admin' | 'original' | '' = '',
    mode: 'image' | 'download' = 'image',
    placeholder?: string,
    format: 'original' | 'webp' = 'original'
  ): string {
    if (typeof value === 'string') {
      return value;
    }
    if (mode === 'image') {
      if (value?.file_type) {
        return this.formatPath(value, view, format);
      } else {
        return placeholder || `assets/images/placeholder-image.svg`;
      }
    } else {
      return this.formatPath(value, view, format);
    }
  }

  formatPath(
    value: UploadFile | undefined | null,
    view: string,
    format: string
  ): any {
    return (
      environment.APP_ENDPOINT +
      '/uploads' +
      (value?.type ? '/' + value.type : '') +
      (view !== '' ? '/' + view : '') +
      '/' +
      (format === 'webp' && value?.filenameWebp
        ? value?.filenameWebp
        : value?.filename)
    );
  }
}

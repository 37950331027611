import { AbstractControl } from '@angular/forms';
import { PropList } from '../models/models';
import { InjectionToken } from '@angular/core';

export const APP_LOCALE = 'en';
export const APP_CURRENCY_CODE = 'USD';
export const DEFAULT_STATE = {
  text: 'New Jersey',
  id: 'NJ'
};
export const PROFILE_LINK = '/profile/my-dashboard';
export const QUIZ_LINK = '/survey/questions';
export const ORDER_STORAGE_KEY = 'order_data';
export const AUTH_TOKEN = 'token';
export const APP_HTTP_ERROR_MESSAGE =
  'Sorry there was an error, please try again.';
export const APP_DATA_ERROR_MESSAGE =
  'Incorrect data has been entered. Please try again.';
export const APP_SESSION_HAS_EXPIRED =
  'The session is over, you have been logged out';
export const APP_LIST_PLACEHOLDER_DATA = {
  count: 0,
  data: [],
  statusCount: {}
};
export const BMI_INDEX_ALLOWED_NEW = 27;
export const BMI_INDEX_ALLOWED_GLP = 23;
export const PASSWORD_HINT =
  'Using the combination of upper and lower case letters, numbers and symbols like (&#64;, #, $, %, ^, (,), &, !). The minimum length is 6 characters.';
export const PRODUCT_HINT = `As you can see above different rates apply to different medications. During your telehealth consultation, you and your provider may decide to utilize a different medication than the one you choose today. You and your provider may also decide that you will not move forward with any medication. Don't worry we will refund or charge you based on medication changes. The decision to prescribe medication is determined by your provider.`;

export const STARTED_TWO_MONTH_DOSE_ID = 'n2YfTuENwFDOSbfl6nWazKGf75KiCtxd';

export const PHONE_DISCLAMER = `** No mobile information will be shared with unaffiliated third parties for markteting/promotional purposes. All the above categories exclude text messaging originator opt-in data and consent; this information will not be shared with unaffiliated third parties.`;

export enum ResponseMessages {
  LOGOUT_MESSAGE = 'You have been successfully logged out',
  FORM_SEND = 'The form was successfully send',
  REVIEW_SEND = 'The review was successfully send',
  REQUEST_SEND = 'The request was successfully send',
  CONTACT_SENT = 'Contact information was sent',
  ADDRESS_SAVE = 'The address was successfully saved',
  PAYMENT_CONFIRMED = 'Thank you! Payment Confirmed.',
  ACCOUNT_CREATED = 'The account was successfully created',
  INFORMATION_SAVED = 'The information was successfully saved',
  PAYMENT_LINK_SENT = 'The link was successfully sent',
  TEXT_COPY = 'The text has been copied!',
  LINK_COPY = 'The link has been copied!',
  USER_DATA_UPDATE = 'User data was successfully updated',
  USER_PASSWORD_UPDATE = 'Password was successfully updated',
  USER_PASSWORD_CREATED = 'Password was successfully created',
  EMAIL_CODE_SEND = 'The verification code was successfully sent to your email box',
  PHONE_PIN_SEND = 'The verification code was successfully sent to your phone number',
  EMAIL_VERIFIED = 'The email was successfully verified',
  PHONE_VERIFIED = 'The phone was successfully verified',
  QUIZ_SAVED = 'The questionnaire was successfully updated',
  ORDER_CREATED = 'The order was successfully created',
  QUIZ_DOWNLOAD = 'The questionnaire was download successfully',
  PAYMENT_TIMEOUT_MESSAGE = 'Patient has not completed payment within 5 minutes. Please follow up urgently.',
  ORDER_NOT_FOUNR = 'Order not found',
  PRODUCT_NOT_FOUND = 'Product not found',
  LINK_INVALID = 'Link is not valid',
  FOLLOWUP_NOT_LOGIN = 'Please login to get started',
  FOLLOW_UP_COMPLETED = 'You have completed this follow up already. You will receive the next reminder in your email. Thank you.',
  FOLLOW_UP_SAVED = 'You have completed the follow up. Thank you.',
  PHOTO_MAX_SIZE = 'The maximum photo size is 100Mb',
  DOCUMENT_MAX_SIZE = 'The maximum document size is 100Mb',
  RESET_PASSWORD_LINK = 'The reset link was successfully sent to patient email box',
  CANCELED_SUBSCRIPTION = 'Thank you. We have sent your request. You will be contacted soon regarding your Request.',
  PAUSE_SUBSCRIPTION = 'Subscription successfully paused',
  RENEW_SUBSCRIPTION = 'Subscription successfully renewed',
  PATIENT_DELETED = 'The patient was successfully deleted',
  PATIENT_PAID_ORDER = 'The user has already paid for this product',
  USER_NOT_FOUND = 'The user not found',
  ADD_TRACKING_NUMBER = 'Tracking Number was successfully send',
  ADD_ORDER_NOTE = 'Note was successfully send',
  MEDICATION_FEEDBACK_SENT = 'The feedback was successfully sent'
}

export enum PaymentStatus {
  PAYMENT_SENT = 1,
  PAYMENT_COMPLETE = 2,
  PAYMENT_TIMEOUT = 3
}

export enum UserRole {
  CUSTOMER = 1,
  SALES = 2,
  ADMIN = 3,
  AFFILIATE = 4
}

export interface RegimenItem {
  title: string;
  value: number;
  subscription?: boolean;
}

export const regimens = [
  {
    title: '4 weeks',
    value: 1
  },
  {
    title: '8 weeks',
    subscription: true,
    value: 1
  },
  {
    title: '12 weeks',
    value: 3
  },
  {
    title: '24 weeks',
    value: 6
  }
];

export function RegimenList(
  isSubscription: boolean,
  allRegimes?: boolean
): RegimenItem[] {
  if (allRegimes) {
    return regimens;
  }
  if (isSubscription) {
    return [regimens[1], regimens[2], regimens[3]];
  } else {
    return [regimens[0], regimens[2], regimens[3]];
  }
}

export const STATE_LIST: PropList = {
  AL: { text: 'Alabama' },
  AK: { text: 'Alaska' },
  AZ: { text: 'Arizona' },
  AR: { text: 'Arkansas' },
  CA: { text: 'California' },
  CO: { text: 'Colorado' },
  CT: { text: 'Connecticut' },
  DE: { text: 'Delaware' },
  FL: { text: 'Florida' },
  GA: { text: 'Georgia' },
  HI: { text: 'Hawaii' },
  ID: { text: 'Idaho' },
  IL: { text: 'Illinois' },
  IN: { text: 'Indiana' },
  IA: { text: 'Iowa' },
  KS: { text: 'Kansas' },
  KY: { text: 'Kentucky' },
  LA: { text: 'Louisiana' },
  ME: { text: 'Maine' },
  MD: { text: 'Maryland' },
  MA: { text: 'Massachusetts' },
  MI: { text: 'Michigan' },
  MN: { text: 'Minnesota' },
  MS: { text: 'Mississippi' },
  MO: { text: 'Missouri' },
  MT: { text: 'Montana' },
  NE: { text: 'Nebraska' },
  NV: { text: 'Nevada' },
  NH: { text: 'New Hampshire' },
  NJ: { text: 'New Jersey' },
  NM: { text: 'New Mexico' },
  NY: { text: 'New York' },
  NC: { text: 'North Carolina' },
  ND: { text: 'North Dakota' },
  OH: { text: 'Ohio' },
  OK: { text: 'Oklahoma' },
  OR: { text: 'Oregon' },
  PA: { text: 'Pennsylvania' },
  RI: { text: 'Rhode Island' },
  SC: { text: 'South Carolina' },
  SD: { text: 'South Dakota' },
  TN: { text: 'Tennessee' },
  TX: { text: 'Texas' },
  UT: { text: 'Utah' },
  VT: { text: 'Vermont' },
  VA: { text: 'Virginia' },
  WA: { text: 'Washington' },
  WV: { text: 'West Virginia' },
  WI: { text: 'Wisconsin' },
  WY: { text: 'Wyoming' }
};

export const forbiddenWeightlossStates = [
  'MS',
  'LA',
  'NM',
  'AK',
  'HI',
  'CA',
  'AR',
  'ME'
];

export const forbiddenGeneticStates = ['NY', 'CA', 'RI', 'CT'];

export default function fromErrorMessage(control: AbstractControl): string {
  if (control.errors?.['required']) {
    return 'Required';
  } else {
    return '';
  }
}

export const WINDOW = new InjectionToken<Window | any>('WindowToken', {
  factory: () => {
    if (typeof window !== 'undefined') {
      return window;
    }
    return new Window();
  }
});

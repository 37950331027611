import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UploadFile } from 'src/app/core/models/models';
import { PathImagePipe } from '../../../pipes/path-image.pipe';
import { AltImagePipe } from 'src/app/shared/pipes/alt-image.pipe';

@Component({
  selector: 'app-image-item',
  standalone: true,
  imports: [PathImagePipe, AltImagePipe],
  templateUrl: './image-item.component.html',
  styleUrl: './image-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageItemComponent {
  @Input() image!: UploadFile | undefined;
  @Input() view: 'admin' | 'original' | '' = '';
  @Input() classes!: string;
}
